@font-face {
  font-family: BuenosAires;
  src: url('../fonts/BuenosAiresWeb-Regular.eot');
  src: url('../fonts/BuenosAiresWeb-Regular.woff2') format('woff2'),
    url('../fonts/BuenosAiresWeb-Regular.woff') format('woff'),
    url('../fonts/BuenosAiresWeb-Regular.otf') format('opentype');
}

@font-face {
  font-family: BuenosAires;
  font-weight: 700;
  src: url('../fonts/BuenosAires-Bold.eot');
  src: url('../fonts/BuenosAires-Bold.woff2') format('woff2'),
    url('../fonts/BuenosAires-Bold.woff') format('woff'),
    url('../fonts/BuenosAires-Bold.otf') format('opentype');
}

/* Deprecated, do not use */
@font-face {
  font-family: Gilroy;
  src: url('../fonts/gilroy-regular-webfont.woff2') format('woff2'),
    url('../fonts/gilroy-regular-webfont.woff') format('woff'),
    url('../fonts/gilroy-regular-webfont.ttf') format('truetype'),
    url('../fonts/Gilroy-Regular.otf') format('opentype');
}

@font-face {
  font-family: Gilroy;
  font-weight: 500;
  src: url('../fonts/gilroy-medium-webfont.woff2') format('woff2'),
    url('../fonts/gilroy-medium-webfont.woff') format('woff'),
    url('../fonts/gilroy-medium-webfont.ttf') format('truetype'),
    url('../fonts/Gilroy-Medium.otf') format('opentype');
}

/* Deprecated, do not use */
@font-face {
  font-family: Gilroy;
  font-weight: 600;
  src: url('../fonts/gilroy-semibold-webfont.woff2') format('woff2'),
    url('../fonts/gilroy-semibold-webfont.woff') format('woff'),
    url('../fonts/gilroy-semibold-webfont.ttf') format('truetype'),
    url('../fonts/Gilroy-SemiBold.otf') format('opentype');
}

@font-face {
  font-family: Gilroy;
  font-weight: 700;
  src: url('../fonts/gilroy-bold-webfont.woff2') format('woff2'),
    url('../fonts/gilroy-bold-webfont.ttf') format('truetype'),
    url('../fonts/Gilroy-Bold.otf') format('opentype');
}

/* Deprecated, do not use */
@font-face {
  font-family: Gilroy;
  font-weight: 800;
  src: url('../fonts/gilroy-extrabold-webfont.woff2') format('woff2'),
    url('../fonts/gilroy-extrabold-webfont.woff') format('woff'),
    url('../fonts/Gilroy-ExtraBold.otf') format('opentype');
}

/* Deprecated, do not use */
@font-face {
  font-family: Gilroy;
  font-weight: 900;
  src: url('../fonts/gilroy-black-webfont.woff2') format('woff2'),
    url('../fonts/gilroy-black-webfont.woff') format('woff'),
    url('../fonts/gilroy-black-webfont.ttf') format('truetype'),
    url('../fonts/Gilroy-Black.otf') format('opentype');
}
