/* stylelint-disable selector-class-pattern, selector-id-pattern */

@import '~simple-css-reset/reset.css';

:root {
  --medzy-color-accent-rgb: 47 108 246;
  --medzy-color-accent: #2f6cf6;
  --medzy-color-accent-bg: #eaf0fe;
  --medzy-color-blue-bg: #e8f2ff;
  --medzy-color-blue-text: var(--medzy-color-accent);
  --medzy-color-green-bg: #e4f3eb;
  --medzy-color-green-medium: #3ba06b;
  --medzy-color-green-text: #1c5017;
  --medzy-color-neutral: #f6f8fa;
  --medzy-color-neutral-bg: #f6f7f9;
  --medzy-color-night-rgb: 11 40 82;
  --medzy-color-night: #0b2852;
  --medzy-color-red-bg: #fff2f0;
  --medzy-color-red-medium: #e84e3f;
  --medzy-color-red-text: #840800;
  --medzy-color-salt: #dde5ed;
  --medzy-color-strong: #131e29;
  --medzy-color-turquoise-bg: #e8f3f6;
  --medzy-color-turquoise-medium: #359cb1;
  --medzy-color-yellow-bg: #fdf8d5;
  --medzy-color-yellow-medium: #ebb114;
  --medzy-color-yellow-text: #682f00;
  --medzy-color-lite-yellow-bg: #fffce6;
  --medzy-font-family-normal: Gilroy, sans-serif;
  --medzy-font-family-title: BuenosAires, sans-serif;
  --ion-safe-area-top: env(safe-area-inset-top);
  --ion-font-family: Gilroy, sans-serif;
  --ion-color-medium: #94a2b4;
  --ion-color-medium-rgb: 148, 162, 180;
  --ion-color-medium-contrast: var(--medzy-color-night);
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #828f9e;
  --ion-color-medium-tint: #9fabbc;
  --ion-color-primary: var(--medzy-color-accent);
  --ion-item-border-color: var(--ion-color-medium);
  --ion-item-color: var(--medzy-color-night);
  --ion-tab-bar-color: var(--medzy-color-night);
  --ion-tab-bar-color-selected: #186aff;
  --ion-tab-bar-background: #e7f3f6;
  --ion-tab-bar-border-color: #e7f3f6;
  --ion-text-color: var(--medzy-color-night);
  --ion-toolbar-background: #bbdde6;
  --ion-toolbar-border-color: #bbdde6;
  --ion-toolbar-color: var(--medzy-color-night);
}

:root .new-app-experience {
  --color: var(--medzy-color-night);
  --ion-tab-bar-color: rgb(var(--medzy-color-night-rgb) / 0.5);
  --ion-tab-bar-color-selected: var(--medzy-color-night);
  --ion-tab-bar-background: #fff;
  --ion-tab-bar-border-color: #fff;
  --ion-toolbar-background: #fff;
  --ion-toolbar-border-color: #fff;

  max-width: 1440px;
  margin: auto;
}

/* Disable header shadow on Android */
.header-md::after {
  display: none;
}

html {
  overflow-y: visible;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;
  color: var(--medzy-color-night);
}

body {
  position: relative;
  font-family: Gilroy, sans-serif !important;
  font-size: 14px;
  font-weight: 500;
  color: var(--medzy-color-night);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  isolation: isolate;
}

html,
body,
#root {
  height: 100%;
}

main {
  display: block;
  width: 100%;
}

pre {
  overflow-x: scroll;
  max-width: 100%;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: #2f2f2f;
  box-shadow: rgb(0 0 0 / 0.35) 0 5px 15px;
  font-size: 13px !important;
  color: lightgreen;
}

p {
  margin-bottom: 11px;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

address {
  font-style: normal;
}

h1 {
  margin: 20px 0 10px;
  font-family: BuenosAires, sans-serif !important;
  font-size: 50px !important;
  font-weight: 800 !important;
  line-height: 1em !important;
}

h2 {
  margin: 0 0 10px;
  font-family: BuenosAires, sans-serif;
  font-size: 30px !important;
  font-weight: 800 !important;
  line-height: 1em !important;
}

h3 {
  margin: 0 0 10px;
  font-family: BuenosAires, sans-serif;
  font-size: 22px !important;
  font-weight: 800 !important;
  line-height: 1em !important;
}

h4 {
  margin: 0 0 10px;
  font-family: BuenosAires, sans-serif;
  font-size: 18px !important;
  font-weight: 800 !important;
  line-height: 1.18em !important;
}

.mdl-shadow--2dp {
  box-shadow: none !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

::placeholder {
  text-overflow: ellipsis;
  color: rgb(var(--medzy-color-night-rgb) / 0.5);
}

button,
input,
label,
textarea {
  font-family: Gilroy, sans-serif;
  font-size: inherit;
  font-weight: inherit;
  color: var(--medzy-color-night-rgb);
}

input:read-only,
textarea:read-only {
  border-color: var(--medzy-color-neutral) !important;
  background-color: var(--medzy-color-neutral) !important;
  color: var(--medzy-color-night-rgb) !important;
}

a {
  opacity: 1;
  text-decoration: none;
  color: var(--medzy-color-accent);
}

a:hover {
  opacity: 1;
  text-decoration: underline;
  color: var(--medzy-color-accent);
}

.calendly-close-overlay {
  background-color: var(--medzy-color-night);
}

iframe#launcher {
  display: none !important;
}

iframe#webWidget {
  padding-top: env(safe-area-inset-top);
}

ion-toolbar {
  --min-height: 60px !important;
}

.ywa-10000 {
  display: none;
}

.ion-action-sheet-medzy .action-sheet-group button:last-of-type {
  margin-bottom: 16px;
}

.ion-action-sheet-medzy .action-sheet-group button + button {
  margin-top: 8px;
}

.ion-action-sheet-medzy .action-sheet-group .action-sheet-title {
  align-items: center;
  text-align: center;
  font-family: var(--medzy-font-family-title);
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: var(--medzy-color-night);
}

/* Removes weird line that appears between the title and buttons on the ios action sheet */
.ion-action-sheet-medzy .action-sheet-title.sc-ion-action-sheet-ios {
  background: none;
}

.ion-action-sheet-medzy .action-sheet-group .action-sheet-sub-title {
  font-family: var(--medzy-font-family-normal);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: var(--medzy-color-night);
}

.action-sheet-container .action-sheet-button {
  width: 295px;
  height: 40px;
  padding: 11px 18px 12px;
  border-radius: 8px;
  margin: auto;

  font-family: var(--medzy-font-family-title);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

/* Centers text for ion-sheet for android layout */
.ion-action-sheet-medzy .action-sheet-group button .action-sheet-button-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Button with role "confirm" */
.action-sheet-container .action-sheet-button.action-sheet-confirm {
  background: var(--medzy-color-accent);
  color: #fff;
}

@media (max-width: 768px) {
  #webWidget {
    height: calc(100% - env(safe-area-inset-bottom)) !important;
  }

  .calendly-overlay .calendly-popup-close {
    top: calc(10px + env(safe-area-inset-top)) !important;
  }

  .calendly-overlay .calendly-popup {
    top: calc(60px + env(safe-area-inset-top)) !important;
  }
}

ion-modal.modal-default {
  pointer-events: none;
}

ion-modal::part(backdrop) {
  --backdrop-opacity: 0;
  pointer-events: none;
}

@media (min-height: 600px) and (min-width: 768px) {
  ion-modal {
    --width: 100%;
    --height: 100%;
    --border-radius: 0;
  }
}

@media (min-height: 600px) and (min-width: 993px) {
  ion-modal {
    --width: calc(43.75% + 63px); /* Matches the with of the content */
    --height: calc(100% - 48px);
    --border-radius: 16px;
    justify-content: unset; /* Override the default centering css */
  }

  ion-modal::part(content) {
    box-sizing: border-box;
    position: relative;

    /* Align the modal with the main content (that is not really centered) */
    left: calc(25% - 32px);
    border: 1px solid var(--medzy-color-salt);
  }
}

/* stylelint-enable selector-class-pattern, selector-id-pattern */
